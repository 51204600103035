
.react-tel-input .form-control:focus {
  box-shadow: none !important;
  border-color: #001489 !important;
}

.react-tel-input .form-control:focus + .flag-dropdown {
  box-shadow: none !important;
  border-left: #001489 !important;
  border-top: #001489 !important;
  border-bottom: #001489 !important;
}
