.settings-container {
    min-height: 85px;
    border-radius: 0px;
}

.user-info-container {
    background: #F1F1F1;
    height: 140px;
    display: flex;
    justify-content: space-between;
}

.padding-0-30 {
    padding: 0px 30px;
}

.user-avatar {
    width: 70px;
    height: 70px;
    background-image: url(../images/user-icon.png);
    border-radius: 50%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.user-name-label {
    font-family: Roboto;
    font-size: 22px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
}

.user-email-label {
    font-family: Roboto;
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;

}

.fa-chevron-right {
    color: #717171;
}

.settings-element {
    margin: 20px 20px 0px 20px;
    height: 55px;
    padding: 8px 16px 8px 16px;
    border-radius: 12px;
    background-color: #F8F8F8;
    cursor: pointer;
}

.lh-38 {
    line-height: 38px;
}