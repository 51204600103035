* {
    font-family: Roboto;
}

.top-section {
    background: linear-gradient(113.15deg, #B239C6 8.92%, #7715D3 90.2%);
    width: 100%;
    height: 276px;
}

.top-section-green {
    background: linear-gradient(113.15deg, #38CAA5 8.92%, #219AB5 90.2%);
    width: 100%;
    height: 276px;
}

.top-section-header {
    height: 72px;
    top: 95px;
    left: 27px;
    position: relative;
    font-family: Roboto;
    font-size: 36px;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: left;
    color: #FFFFFF;
}

.top-section-w1 {
    width: 270px;
}

.top-section-w2 {
    width: 245px;
}

.font-600 {
    font-weight: 600;
}

.font-300 {
    font-weight: 300;
}

.bottom-form {
    position: fixed;
    width: 100%;
    height: 580px;
    top: 232px;
    padding: 24px 24px 42px 24px;
    border-radius: 32px 32px 0px 0px;
    gap: 32px;
    background-color: #FFFFFF;
}

.btn-purple,
button.ac-pushButton.style-default {
    background-color: #974ce8;
    border-color: #974ce8;
    color: white;
    width: 100%;
    border-radius: 10px;
}

.btn-purple:hover,
button.ac-pushButton.style-default:hover {
    background-color: #8033d3;
    border-color: #974ce8;
    border-color: #8033d3;
    color: white;
    border-radius: 10px;
}

.btn-purple:active,
button.ac-pushButton.style-default:active {
    background-color: #7630c0 !important;
    border-color: #974ce8 !important;
}

.btn-purple:disabled {
    background-color: #9265c2 !important;
    border-color: #9265c2 !important;
}

.btn-green {
    background-color: #5bd0c0;
    border-color: #5bd0c0;
    width: 100%;
}

.btn-green:hover {
    background-color: #51c6b6;
    border-color: #51c6b6;
    border-color: #5bd0c0;
}

.btn-green:active {
    background-color: #4bb7a9 !important;
    border-color: #4bb7a9 !important;
    border-color: #5bd0c0 !important;
}

.btn-white {
    background-color: #FFFFFF;
    border-color: silver;
    color: #6e6e6e;
    width: 100%;
}

.btn-white:hover,
.btn-white:active {
    background-color: #f0ebeb !important;
    border-color: silver !important;
    color: #6e6e6e !important;
}

.hr-effect {
    height: 1px;
    background-color: silver;
}

.text-light-shade {
    color: #6e6e6e;
}

.color-purple {
    color: #974ce8;
}

.color-green {
    color: #5bd0c0;
}

.h-3 {
    height: 3rem;
}

.lh-5 {
    line-height: 5rem;
    ;
}

.fw-300 {
    font-weight: 300;
}

.fw-200 {
    font-weight: 200;
}

.ft-40 {
    font-size: 40px;
}

.ft-2 {
    font-size: 2rem;
}

.ft-15 {
    font-size: 15px;
}

.fw-600 {
    font-weight: 600;
}

.fw-400 {
    font-weight: 400;
}

.bold {
    font-weight: bold;
}

.gear {
    font-size: 25px;
}

/* mobile common */
.top-header {
    min-height: 141px;
    padding-top: 35px;
    ;
    border-radius: 0px 0px 16px 16px;
    background-color: #F8F8F8;
    z-index: 9999 !important;
    width: 100%;
}

.page-header-container {
    height: 32px;
    padding: 0px 25px 0px 25px;
    justify-content: space-between;
    display: flex;
    align-items: center;
}

.page-title {
    /* font-family: Roboto; */
    font-size: 30px;
    font-weight: 600;
    line-height: 22px;
    font-weight: 500;
    letter-spacing: 0em;
    text-align: left;
    color: #4D4D4D;
    padding: 0px 0px 0px 17px;
}

.caps {
    width: 35px;
    height: 35px;
    padding: 7px;
    border-radius: 8px;
    background-color: #E1E1E1;
}

.toggle-button-effect {
    display: flex;
    padding: 0px 25px 10px 25px;
}

.toggle-active-btn-left {
    background-color: white;
    color: #4D4D4D !important;
    border-color: transparent;
}

.toggle-active-btn-left:hover,
.toggle-active-left,
.toggle-active-btn-left:active {
    position: relative;
    background-color: #974ce8;
    right: -7px;
    color: white !important;
}

.toggle-active-btn-right {
    background-color: white;
    color: #4D4D4D !important;
    border-color: transparent;
}

.toggle-active-btn-right:hover,
.toggle-active-btn-right:active {
    position: relative;
    left: -7px;
    background-color: #974ce8;
    color: white !important;
}

.input-group>.form-control,
.input-group>.form-floating,
.input-group>.form-select {
    border-color: #dee2e6 !important;
}

.li-style-none {
    list-style-type: none;
}

.no-button-effect {
    background-color: transparent;
    border: none;
}

.nameUpdateConainer {
    background: linear-gradient(159.85deg, #38CAA5 6.82%, #219AB5 89.5%);
    width: 100%;
    height: 100vh
}

.username-label {
    width: 320px;
    height: 72px;
    padding-top: 130px;
    font-family: Roboto;
    font-size: 36px;
    font-weight: 300;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: left;
    color: #FFFFFF;
}

.username-input {
    width: 320px;
    height: 60px;
    border-radius: 8px;
    margin-top: 140px;
    font-family: Roboto;
    font-size: 30px;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: center;
    color: #FFFFFF;
    padding: 12px 40px;
    background-color: transparent;
    border: none;
    box-shadow: 0px 4px 8px 0px #1C6A5D33;
}

.username-input::placeholder {
    color: #89d3d1;
}

.username-button {
    width: 320px;
    height: 54px;
    margin-top: 180px;
    border-radius: 8px;
    background: #FFFFFF;
    border-color: transparent;
    box-shadow: 0px 8px 16px 0px #219AB5;
    font-size: 17px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: center;
    color: #38CAA5;
}

.login-welcome-wrapper {
    background: linear-gradient(159.85deg, #B138C5 6.82%, #7615D3 89.5%);
}

.login-welcome-label {
    font-family: Roboto;
    font-size: 36px;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: center;
    padding-top: 340px;
}

.welcome-description {
    width: 260px;
    height: 52px;
    font-family: Roboto;
    font-size: 22px;
    font-weight: 300;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: center;
    color: #FFFFFF;
    padding-top: 40px;
}

.welcome-button {
    box-shadow: 0px 8px 16px 0px #4B237F1A;
    color: #4D4D4D;
    font-family: Roboto;
    font-size: 17px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: center;
    margin-top: 180px;

}

.css-1t62idy.css-uohcgx.react-web-chat.web-chat {
    border-color: transparent !important;
}

.toolbox-heading {
    width: 223px;
    height: 35px;
    top: 152px;
    left: 82px;
    font-family: Roboto;
    font-size: 22px;
    font-weight: 600;
    line-height: 37px;
    letter-spacing: 0em;
    text-align: left;
    margin-left: 30px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.toolbox-navigate-prev {
    background-color: #eadbfa;
    border-radius: 5.782608985900879px;
    padding: 5px;
    font-size: 25px;
}

.toolbox-add-btn,
.toolbox-add-btn:active,
.toolbox-add-btn:hover {
    width: 50%;
    border-radius: 4px;
}

.hr-toolbox {
    color: #9b68d3c4;
}

.toolbox-border-light {
    border: 1px solid #974CE833;
}

.list-element {
    font-family: Roboto;
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0em;
    text-align: left;
    width: 50px;
    height: 50px;
    padding-top: 14px;
    text-align: center;
    border-right: 1px solid #ebd9fa;
}

.inner-top-element {
    font-family: Roboto;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
}

.list-caption {
    font-family: Roboto;
    font-size: 10px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
    color: #6B6B6B;
}

.toolbox-right-icon {
    background-color: #974CE833;
    padding: 27px 0px 10px 0px;
    height: 71px;
    width: 50px;
    margin: 1px -4px 1px 0px;
    border-radius: 0px 5px 5px 0px;
    color: #974ce8;
}

/* .disabled-button {
    background-color: rgb(234, 234, 234) !important;
    color: white;
    cursor: not-allowed;
  } */

.m-center {
    margin: 0 auto;
}

.w-80 {
    width: 80%;
}

.toolbox-list-element {
    border-radius: 5px;
}

.bg-transparent {
    background-color: transparent;
}

.modal-close-btn-updated {
    background: transparent;
    border: none;
    font-size: 30px;
    font-weight: 200;
}

.modal-header-wrapper {
    min-width: 338px;
    width: max-content;
    margin: 0px auto 0px auto;
    padding-top: 15px;
}

.modal-footer-wrapper {
    width: 338px;
    margin: 0px auto 16px auto;
}

.modal-header {
    height: 73px;
    padding: 18px;
    border-bottom: 1px solid #9b68d3c4;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
}

.modal-footer {
    padding: 25px;
    border-top: 1px solid #984ce9;
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;
}

.lh-45 {
    line-height: 45px;
}

.bl-purple {
    border-left: 1px solid #984ce9;
}

.br-purple {
    border-right: 1px solid #984ce9;
}

.bt-purple {
    border-top: 1px solid #984ce9;
}

.bb-purple {
    border-bottom: 1px solid #984ce9;
}

.bottom-left-24-rd {
    border-bottom-left-radius: 24px;
}

.bottom-right-24-rd {
    border-bottom-right-radius: 24px;
}

.text-area-text {
    font-family: Roboto;
    font-size: 11px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
    padding: 10px;
}

.tool-textarea {
    width: 100%;
    height: 100%;
    border: 0;
}

.bg-purple {
    background-color: #974ce8;
    color: white !important;
}

.overflow-wrap {
    overflow-wrap: break-word;
}

.dropdown-container {
    position: relative;
    display: inline-block;
}

.gear-icon {
    cursor: pointer;
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    right: 0;
    background-color: #ffffff;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 100;
}

.dropdown-menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.dropdown-menu li {
    padding: 8px 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.dropdown-menu li:hover {
    background-color: #f2f2f2;
}

.top-146 {
    /* top: 146px; */
}

.w-98 {
    width: 98%;
}

._3cstool {
    overflow-y: auto;
    height: 90vh;
}

.self-reminder {
    height: 5px;
    width: 5px;
    background: rgba(253, 224, 48, 1);
    border-radius: 50%;
    margin: 5px 0px 5px 25px;
    position: absolute;
}

.removal-reminder {
    width: 290px;
    height: 70px;
    border-radius: 5px;
    background: rgba(233, 213, 255, 0.32);
    margin: 0 auto;
    margin-bottom: 15px;
}

.reminder-text {
    font-family: Roboto;
    font-size: 13px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
    width: 185px;
    color: rgba(112, 112, 112, 1);
}

.inner-removal-container {
    height: 100%;
    padding: 15px;
}

.reminder-note-cross-icon {
    color: rgba(151, 76, 232, 0.2);
}

.light-silver-border,
input.form-control.mt-1.schedule-headings.p-20.light-silver-border,
input.light-silver-border {
    border-color: #e1e1e1 !important;
    border-radius: 5px;
}

.w-344 {
    width: 344px;
}

.color-purple-option {
    background-color: #974ce8;
}

span.MuiSlider-root.MuiSlider-colorPrimary.MuiSlider-sizeMedium {
    color: #984ce9 !important;
}

.span.MuiSlider-valueLabelLabel {
    background-color: red !important;
    color: white !important;
}

.reassessment-wrap {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 590px;
    overflow-y: scroll;
    bottom: 0px !important;
    top: 260px;
}

.reassessment-sub-wrap {
    width: fit-content;
    max-width: 330px;
    ;
    margin: 0 auto;
}

.w-85 {
    width: 85%;
}

.fa-arrow-left,
.fa-solid {
    color: #4D4D4D;
}

span.MuiSwitch-root.MuiSwitch-sizeMedium {
    height: 38px;
}

.home-screen-header {
    min-height: 100px;
    border-radius: 0px;
}

.circular-progress {
    position: relative;
    width: 90px;
    height: 90px;
    margin-left: 2em;
}

.svg {
    transform: rotate(-230deg);
}

.circle-background {
    fill: none;
    stroke: #ccc;
    /* Background color of the circle */
    stroke-width: 8;
}

.circle-progress {
    fill: none;
    stroke: #35D0C0;
    stroke-width: 8;
    stroke-linecap: round;
    transition: stroke-dashoffset 0.5s;
}

.percentage {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 12px;
    font-weight: bold;
    color: #35D0C0;
    text-align: center;
}

.home-menu-container {
    width: 160px;
    height: 140px;
    padding: 19px 15px 19px 15px;
    border-radius: 12px;
}

.home-sub-container {
    /* margin-top: 260px; */
}

.purple-menu-bg {
    background: linear-gradient(99.8deg, #B138C5 0%, #7615D3 100%);
}

.pink-menu-bg {
    background: linear-gradient(99.8deg, #FF5B7A 0%, #D84188 100%);
}

.menu-name {
    font-family: Roboto;
    font-size: 22px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    color: white;
}

.submenu-name {
    font-family: Roboto;
    font-size: 12px;
    /* font-weight: 100; */
    line-height: 20px;
    letter-spacing: 0.3px;
    text-align: left;
    color: white;
}

.menu-text {
    margin-left: 5px;
}

.home-logo-img {
    background-image: url(../../public/logo.png);
    height: 84px;
    width: 121px;
    display: block;
    background-repeat: no-repeat;
    background-size: contain;
}

.selectLanguageContainer {
    padding-top: 100px;
    width: 305px;
    margin: 0 auto;
    text-align: center;
}

.subscription-card {
    margin: 20px;
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.subscription-card:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.subscriptionConainer {
    background: linear-gradient(159.85deg, #38CAA5 6.82%, #219AB5 89.5%);
    width: 100%;
    height: 100vh
}

.card-header {
    background: linear-gradient(159.85deg, #38CAA5 6.82%, #219AB5 89.5%);
    color: #fff;
    padding: 10px;
}

.pt-40 {
    padding-top: 40px !important;
}

.username-sub-label {
    width: 340px;
    height: 30px;
    padding-top: 20px;
    font-family: Roboto;
    font-size: 24px;
    font-weight: 200;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    color: #FFFFFF;
}

.mt-30 {
    margin-top: 30px;
}

@media screen and (min-width: 768px) {

    .adjust-to-middle,
    .form-group.bottom-form {
        width: 50% !important;
        position: absolute;
        left: 50%;
        transform: translate(-50%, 0%)
    }
}

@media (max-width: 768px) {

    .hr-parent-container {
        position: relative;
    }

    .hr-effect {
        width: 25%;
        position: absolute;
    }

    .hr-right {
        right: 0px;
    }
}

.w-95 {
    width: 95%;
}

.home .title {
    font-family: Roboto;
    font-size: 22px;
    font-weight: 600;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;   
    margin-left: 20px;
}

.home .title-container {
    padding: 16px;
}

.home .statName {
    font-size: 26px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
}

.home .statScore{
    font-size: 26px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
}

.home .statIcon{
    margin-left: 15px;
}


.home .chartScore {
    margin-left: 0;
    line-height: 0;
}

.home {
    height: 100vh;
}