#chat-container {
    max-width: 500px;
    margin: 0px auto;
    background-color: #fff;
    border-radius: 5px;
    padding: 10px;
}

#chat-display {
    overflow-y: scroll;
    height: 600px;
}

.puple-dot {
    width: 35px;
    height: 35px;
    background-color: #974ce8;
    border-radius: 50%;
    margin-right: 10px !important;
    position: relative;
    top: 5px;
}

.system-input {
    width: 90%;
    justify-content: center;
    padding: 5px 29px 10px 16px;
    background-color: #f8f8f8;
    border-radius: 20px;
    border-top-left-radius: 0px;
    font-size: 20px;
    font-weight: 200;
    color: #5f5e5e;
}

.user-input {
    width: 60%;
    justify-content: center;
    padding: 5px 29px 10px 16px;
    background-color: #eadbfa;
    border-radius: 20px;
    border-top-right-radius: 0px;
    font-size: 20px;
    font-weight: 200;
    color: #5f5e5e;
}

.avatar {
    width: 35px;
    height: 35px;
    background-image: url(../images/profile.jpeg);
    border-radius: 50%;
    margin-left: 10px !important;
    background-position: center;
    background-repeat: no-repeat;
}

#chat-input {
    display: flex;
    margin-top: 20px;
    padding: 0px 16px 0px 16px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

#type-user-input {
    flex: 1;
    padding: 10px;
    border: 1px solid #974ce8;
    border-radius: 10px;
}

.badge-success-custom {
    background-color: #cafbd9;
    color: #3e8a2e;
    position: relative;
    left: 65%;
    bottom: 9px;
    font-weight: 300;
}

.badge-warning-custom {
    background-color: #fef2cb;
    color: #7d5d1e;
    position: relative;
    left: 65%;
    bottom: 9px;
    font-weight: 300;
}

.badge-danger-custom {
    background-color: #fcebeb;
    color: #c33d3d;
    position: relative;
    left: 65%;
    bottom: 9px;
    font-weight: 300;
}

.badge-primary-custom {
    background-color: #eff6fc;
    color: #2c88d9;
    position: relative;
    left: 65%;
    bottom: 9px;
    font-weight: 300;
}

.add-btn {
    font-size: 25px;
    color: #984ce9;
    position: relative;
    left: 88%;
    bottom: 48px;
}

.badge-sky-custom {
    background-color: #54c9dd;
    color: #FFFFFF;
    position: relative;
    left: 5%;
    bottom: 8px;
    font-weight: 300;
    border-bottom-left-radius: 0px;
}

.recording-btn {
    font-size: 2rem;
    color: #974ce8;
    margin-left: 10px;
}

.chat-left-space {
    margin-left: 55px;
    position: relative;
    bottom: 30px;
}

.screen-top-heading {
    height: 80px;
    border-radius: 0px;
}

.screen2-text-container {
    padding: 8px;
    background-color: white;
    border-radius: 8px;
}

.screen2-outer-container {
    padding: 8px;
    background-color: #eadbfa;
    overflow-y: scroll;
    padding-top: 3px;
}

.bg-custom-light {
    background-color: #eadbfa;
}

.modal-button {
    width: 120px;
    height: 40px;
    border-radius: 100px;
}

.modal-text {
    font-family: Hind;
    font-size: 17px;
    font-weight: 300;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    padding: 12px;
    color: #4D4D4D;
    background-color: white;
    border-radius: 8px;
}

.mb-30 {
    margin-bottom: 30px;
}

.primary-btn-color {
    background-color: #0078D4;
    border-color: #0078D4;
}

.success-btn-color {
    background-color: #33CA39;
    border-color: #33CA39;
}

.warning-btn-color {
    background-color: #FFD335;
    border-color: #FFD335;
    color: #743616;
}

.danger-btn-color {
    background-color: #D92C2C;
    border-color: #D92C2C;
}

.modal-content {
    background-color: transparent;
    border: 0px;
}

.modal-backdrop {
    background: rgba(151, 76, 232, 0.2);
    backdrop-filter: blur(10px);
}

.modal-body-scr-3 {
    top: 180px;
}

.mb-170 {
    margin-bottom: 170px;
}

.modal-text-scrn-4 {
    background-color: #F8F8F8;
    width: 357px;
    padding: 12px;
    border-radius: 12px;

}

.modal-scrn-4-top-heading {
    font-size: 17px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #4D4D4D;
    margin-left: 5px;
}

.model-scrn-4-inner-content {
    margin-top: 10px;
    padding: 10px;
    border-radius: 8px;
    background-color: white;
}

.modal-body-scr-4 {
    margin-top: 0px;
}

.modal-scrn-5-top-heading {
    background-color: #E1E1E1;
    width: 169px;
    padding: 8px 35px 8px 35px;
    text-align: center;
}

.model-scrn-5-inner-content {
    position: relative;
    right: 34px;
    width: 167px;
    height: 280px;
    /* padding: 8px 16px 8px 16px; */
    text-align: left;
    color: #717171;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: -0.011em;
    text-align: left;
    border-top: 1px solid #974CE8;
}

.srcn-5-left-hdng {
    border-top-left-radius: 15px;
}

.srcn-5-right-hdng {
    border-top-right-radius: 15px;
}

.srcn-5-b-l-cont {
    border-bottom-left-radius: 15px;
}

.srcn-5-b-r-cont {
    border-bottom-right-radius: 15px;
}

.br-left {
    border-left: 0.5px solid #974CE8;
}

.br-right {
    border-right: 0.5px solid #974CE8;
}

.modal-close-btn {
    position: relative;
    right: 12px;
    margin-left: auto;
    background: transparent;
    border: none;
    font-size: 30px;
    font-weight: 200;
}

.modal-quest {
    background-color: #FFFFFF;
    width: 336px;;
    padding: 12px;
    border-radius: 0px 0px 24px 24px;
}

.progress-bar {
    height: 10px;
    width: 60px;
    margin-right: 2px;
}

.progress-radious-left {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}

.progress-radious-right {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}

.progress-active {
    background-color: #984ce9;
}

.progress-inactive {
    background-color: #eadbfa;
}

.close-btn-inside {
    top: -14px !important;
    left: 285px;
}

.popup-header {
    position: absolute;
    top: 60px;
    color: #6E6E6E;
    font-size: 17px;
}

.pl--7 {
    padding-left: 0.7rem;
}

.p--7 {
    padding: 0.7rem;
}

.chat-popup-inner-heading {
    color: #2A2A2A;
    font-size: 22px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
}

.chat-popup-inner-text {
    font-size: 15px;
    font-weight: 300;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
}

.text-area-popup {
    margin-top: 40px;
}

.mt-20 {
    margin-top: 20px;
}

.mt-11 {
    margin-top: 11px;
}

.text-area-itself {
    height: 104px;
    overflow-y: scroll;
}

.modal-discard-save {
    padding: 10px;
    background-color: white;
    border-radius: 24px;
    padding: 52px 33px 52px 33px;
    margin-top: 195px;
    width: fit-content;
    margin: 195px auto auto auto;
}

.btn-purple-light {
    background-color: #eadbfa;
    border-color: #eadbfa;
    color: #984ce9;
}

.btn-purple-light:hover,
.btn-purple-light:active {
    background-color: #984ce9 !important;
    border-color: #984ce9 !important;
    color: white !important;
}

.fs-15 {
    font-size: 15px;
}

.cogntive-headeing {
    font-size: 17px;
    font-weight: 600;
    line-height: 22px;
    color: #000000;
}

.popup-hr-effect {
    height: 20px;
    width: 100%;
    border-bottom: 1px solid #eadbfa;
}

.p-12 {
    padding: 12px;
}

.dark-heading {
    color: #000000;
}

.fs-15 {
    font-size: 15px;
}

.mb-12 {
    margin-bottom: 12px;
}

.mb-15 {
    margin-bottom: 15px !important;
}

.pl-25 {
    padding-left: 25px;
}

.mt-20 {
    margin-top: 10px;
}

.mt-30 {
    margin-top: 30px;
}

.radio-option-containers {
    border: 1px solid #974CE833;
    border-radius: 5px;
}

.ml-34 {
    margin-left: 34px;
}

.mr-20 {
    margin-right: 20px;
}

.fs-10 {
    font-size: 10px;
}

.fs-12 {
    font-size: 12px;
}

.radio-position {
    position: relative;
    top: 7px;
}

.cognitive-container {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 81vh;
}

.cog-header {
    position: relative;
    top: 18px;
}

.input_radio {
    accent-color: #984ce9;
}

.input_radio_yellow {
    accent-color: #fcd84a;
}

.input_radio_blue {
    accent-color: #1269d4;
}

.input_radio_green {
    accent-color: #61d035;
}

.schedule-headings {
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
}

.record-dark {
    color: #000000;
}

.record-light {
    color: #717171;
}

.custom-control-input:not(:checked),
.custom-control-input:focus {
    background-color: #eadbfa;
    border-color: #eadbfa;
}

.custom-control-input:checked {
    background-color: #974CE8;
    border-color: #974CE8;
}

.modal-back-btn {
    left: 20px;
    background: #eadbfa;
    padding: 10px;
    width: 43px;
    font-size: 24px;
    border-radius: 7px;
    color: #984ce9;
}

.p-20 {
    padding: 20px;
}

.radio-label {
    position: relative;
    top: 7px;
}

.radio-container-div {
    border: 1px solid #E1E1E1;
    height: 40px;
    width: 47%;
    border-radius: 5px;
}

.ml-15 {
    margin-left: 15px;
}

.overall-container {
    margin: 0px 20px 0px 20px;
    background-color: white;
    min-width: 338px;
    width: max-content;
    height: 75vh;
    overflow-y: auto;
    border-radius: 0px 0px 24px 24px;
}

/* customization  */

.css-cf1ppa.avatar {
    background: #984ce9 !important;
}

.ac-container.ac-adaptiveCard,
.markdown.css-1b7yvbl {
    justify-content: center;
    padding: 5px 29px 10px 16px;
    background-color: #f8f8f8;
    border-radius: 20px;
    border-top-left-radius: 0px;
    font-size: 20px;
    font-weight: 200;
}

p.plain.css-1b7yvbl {
    justify-content: center;
    padding: 5px 29px 10px 16px;
    background-color: #eadbfa;
    border-radius: 20px;
    border-top-right-radius: 0px;
    font-size: 20px;
    font-weight: 200;
    color: #5f5e5e;
}

.webchat__bubble__content {
    border-color: transparent !important;
}

input[type="text"] {
    flex: 1 1 !important;
    padding: 10px !important;
    border: 1px solid #974ce8 !important;
    border-radius: 10px;
}

button.css-115fwte svg {
    background-color: #8141d3;
    border-radius: 50%;
    height: 35px;
    width: 35px;
    padding-left: 5px;
}

.css-115fwte svg,
[data-css-115fwte] svg {
    fill: white !important;
}

.css-eycyw2>.main,
[data-css-eycyw2]>.main {
    display: none;
}

.react-web-chat.web-chat {
    display: flex;
    flex-direction: column;
    border-radius: 5px;
}

.css-390qnp>button:not(:disabled),
[data-css-390qnp]>button:not(:disabled) {
    border-color: #984ce9 !important;
    color: #984ce9 !important;
}

.markdown {
    justify-content: center;
    padding: 5px 29px 10px 16px;
    background-color: #f8f8f8 !important;
    border-radius: 20px;
    border-top-left-radius: 0px;
    font-size: 20px;
    font-weight: 200;
    color: #5f5e5e !important;
}

p.plain {
    padding: 5px 29px 10px 16px;
    background-color: #eadbfa !important;
    border-radius: 20px;
    border-top-right-radius: 0px;
    font-size: 20px;
    font-weight: 200;
    color: #5f5e5e !important;
}

.webchat__suggested-action,
.webchat__suggested-action:active,
.webchat__suggested-action:hover{
    border-color: #984ce9 !important;
    color: #984ce9 !important;
}

.webchat__initialsAvatar,
.webchat__initialsAvatar--fromUser {
    background-color: #984ce9 !important;
}

button.ac-pushButton.style-default.ac-selectable,
button.ac-pushButton.style-default.ac-selectable:active,
button.ac-pushButton.style-default.ac-selectable:hover {
    background-color: #984ce9 !important;
    border-color: #984ce9 !important;
    color: White !important;
}

.week-sub-heading {
    color: silver;
    font-size: 11px;
}

.h-100vh {
    height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: fixed;
    width: 100%;
}
  
.w-357 {
    width: 357px;
}

.schedule-cart {
    width: 92%;
    margin: 0 auto;
}