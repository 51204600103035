.main-question {
    width: 256px;
    height: auto;
    padding: 8px 12px 8px 12px;
    border-radius: 4px 12px 12px 12px;
    box-shadow: 0px 2px 4px 0px #4B237F33;
    font-family: Roboto;
    font-size: 17px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #4D4D4D;
    margin: 10px;
    margin-left: 20px;
}

.answer-option {
    width: fit-content;
    height: auto;
    padding: 8px 12px 8px 12px;
    border-radius: 12px 4px 12px 12px;
    background-color: #EADBFA;
    box-shadow: 0px 2px 4px 0px #4B237F33;
    font-family: Roboto;
    font-size: 17px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #4D4D4D;
    /* margin: 21px; */
    margin-left: auto;
}

.assign-button {
    width: 356px;
    height: 54px;
    border-radius: 8px;
    background-color: #EADBFA;
    box-shadow: 0px 8px 16px 0px #4B237F1A;
    color: #4D4D4D;
    border-color: transparent;
    font-family: Roboto;
    font-size: 17px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: center;
    color: #4D4D4D;
}

.outer-wrapper {
    /* margin-top: 2rem; */
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
}

.assessment-options-list {
    position: fixed;
    bottom: 0px;
}

.assessment-container-wrapper {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow-y: auto;
}

.assessment-response {
    height: 300px;
    overflow-y: auto;
}