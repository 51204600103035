.chat-box {
  z-index: 100;
  border: 2px solid #7c2d78 !important;
  background-color: rgb(238, 227, 237) !important;
}

div.main > div.css-1lzfo2b.css-14x775w {
display: none;
margin-left: 10%;
}
.maximize {
  z-index: 100;
}

/*
.minimizable-web-chat>.chat-box>header{

  background-color:  hsla(0, 0%, 100%, 1) !important;
  border: 2px solid #7c2d78 !important;  
  background-repeat:no-repeat;
  background-size: 110px 38px
 
}
*/
.minimizable-web-chat > button.maximize {
  background-color: #7c2d78;
  border-radius: 50%;
  border: 2px solid #7c2d78;
  bottom: 20px;
  box-shadow: 0 0 20px rgba(0, 0, 0, .2);
  color: White;
  font-size: 30px;
  height: 64px;
  outline: 0;
  padding: 15px;
  position: fixed;
  right: 20px;
  width: 64px;
}

.minimizable-web-chat > button.maximize > .red-dot {
  color: #EF0000;
  font-size: 14px;
  position: absolute;
  right: 12px;
  top: 12px;
}

.minimizable-web-chat > button.maximize:focus:not(:active),
.minimizable-web-chat > button.maximize:hover:not(:active) {
  background-color: rgb(153, 0, 94);
  border-color: rgb(153, 0, 46);
}

.minimizable-web-chat > button.maximize:active {
  background-color: White;
  color: #7c2d78;
}



/* Extra small devices (portrait phones, less than 576px)
 No media query since this is the default in Bootstrap */
 /* @media (min-width: 300px) { 
 .minimizable-web-chat > .chat-box {
  background-color: rgba(255, 255, 255, .8);
  backdrop-filter: blur(10px);
  border-radius: 4px;
  box-shadow: 0 0 20px rgba(0, 0, 0, .2);
  border: 4px solid #7c2d78;
  bottom: 20px;
  display: flex;
  flex-direction: column;
  max-width: 400px;
  min-width: 320px;
  position: fixed;
  bottom: 0px;
  width: 98%;
  left: 0;
  height: 99%;
}
 } */

 /* @media (min-width: 375px) { 
  .minimizable-web-chat > .chat-box {
   background-color: rgba(255, 255, 255, .8);
   backdrop-filter: blur(10px);
   border-radius: 4px;
   box-shadow: 0 0 20px rgba(0, 0, 0, .2);
   border: 4px solid #7c2d78;
   bottom: 0px;
   display: flex;
   flex-direction: column;
   max-width: 400px;
   min-width: 320px;
   position: fixed;
   width: 98%;
   left: 0;
   height: 82%;
 }
  } */

/* Small devices (landscape phones, 576px and up) */
/* @media (min-width: 576px) { 
  .minimizable-web-chat > .chat-box {
  background-color: rgba(255, 255, 255, .8);
  backdrop-filter: blur(10px);
  border-radius: 4px;
  box-shadow: 0 0 20px rgba(0, 0, 0, .2);
  border: 4px solid #7c2d78;
  bottom: 20px;
  display: flex;
  flex-direction: column;
  max-width: 400px;
  min-width: 320px;
  position: fixed;
  top: 0px;
  width: 98%;
  left: 0;
  height: 99%;
}
} */

/* Medium devices (tablets, 768px and up) */
/* @media (min-width: 768px) { 
  .minimizable-web-chat > .chat-box {
    background-color: rgba(255, 255, 255, .8);
    backdrop-filter: blur(10px);
    border-radius: 4px;
    box-shadow: 0 0 20px rgba(0, 0, 0, .2);
    border: 4px solid #7c2d78;
    bottom: 20px;
    display: flex;
    flex-direction: column;
    max-width: 1330px;
    min-width: 920px;
    position: fixed;
    top: 5%;
    width: 100%;
    left: unset !important;
    height: unset !important;
  }
  
 } */

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 
  .minimizable-web-chat > .chat-box {
    background-color: rgba(255, 255, 255, .8);
    backdrop-filter: blur(10px);
    border-radius: 4px;
    box-shadow: 0 0 20px rgba(0, 0, 0, .2);
    border: 4px solid #7c2d78;
    bottom: 20px;
    display: flex;
    flex-direction: column;
    max-width: 1330px;
    min-width: 920px;
    position: fixed;
    top: 5%;
    bottom: 1%;
    width: 100%;
    left: unset !important;
    height: unset !important;
}
 }

/* // Extra large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {  .minimizable-web-chat > .chat-box {
  background-color: rgba(255, 255, 255, .8);
  backdrop-filter: blur(10px);
  border-radius: 4px;
  box-shadow: 0 0 20px rgba(0, 0, 0, .2);
  border: 4px solid #7c2d78;
  bottom: 20px;
  display: flex;
  flex-direction: column;
  max-width: 1330px;
  min-width: 920px;
  position: fixed;
  top: 5%;
  bottom: 1%;
  width: 100%;
  left: unset !important;
  height: unset !important;
 }
} 

/* // Extra large devices (large desktops, 1200px and up)*/
/* @media (min-width: 1500px) {  .minimizable-web-chat > .chat-box {
  background-color: rgba(255, 255, 255, .8);
  backdrop-filter: blur(10px);
  border-radius: 4px;
  box-shadow: 0 0 20px rgba(0, 0, 0, .2);
  border: 4px solid #7c2d78;
  bottom: 20px;
  display: flex;
  flex-direction: column;
  max-width: 400px;
  min-width: 320px;
  position: fixed;
  top: 15%;
  width: 30%;
  left: unset !important;
  height: unset !important;
 }
}  */

/* @media (min-width: 1900px) {  .minimizable-web-chat > .chat-box {
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(10px);
  border-radius: 4px;
  box-shadow: 0 0 20px rgba(0, 0, 0, .2);
  border: 4px solid #7c2d78;
  bottom: 20px;
  display: flex;
  flex-direction: column;
  max-width: 400px;
  min-width: 320px;
  position: fixed;
  top: 30%;
  width: 30%;
  left: unset !important;
  height: unset !important;
 }
}  */

.minimizable-web-chat > .chat-box.hide {
  display: none;
}

.minimizable-web-chat > .chat-box.left {
  left: 20px;
}

.minimizable-web-chat > .chat-box.right {
  right: 20px;
}

/*
.minimizable-web-chat > .chat-box > header {
  background-color: #7c2d78;
  height: 40px;
  display: flex;
}
*/
.minimizable-web-chat > .chat-box > header > .filler {
  flex: 0 10000 100%;
}

.minimizable-web-chat > .chat-box > header > button {
  width: 40px;
  height: 40px;
  background-color: Transparent;
  border: 0;
  color:black;
  outline: 0;
}

.minimizable-web-chat > .chat-box > header > button:focus:active,
.minimizable-web-chat > .chat-box > header > button:hover:active {
  color: rgba(255, 255, 255, .6);
}

.minimizable-web-chat > .chat-box > header > button:focus:active {
  color: rgba(255, 255, 255, .4);
}

.minimizable-web-chat > .chat-box > .react-web-chat {
  flex: 1;
}


.firstmsg{
  display: block;
  position: fixed;
  bottom: 55px;
  right: 95px;
  color: black;
  border-radius: 12px 12px 0 12px;
  box-shadow: 0 2px 13px 0 rgba(0,0,0,0.5);
  background-color: white;
  padding: 8px 18px;
  opacity: 1;
  transition: opacity 1s ease;
  z-index: 16777270;
  width: 150px;
  font-size: medium;
  animation: slide-up-firstmsg 2s ease-out;
}
.firstmsghide{
  display: none;
}
.firstintrohandwave{
  animation-name: wave-animation;  /* Refers to the name of your @keyframes element below */
  animation-duration: 2.5s;        /* Change to speed up or slow down */
  animation-iteration-count: 2;  /* Never stop waving :) */
  transform-origin: 70% 70%;       /* Pivot around the bottom-left palm */
  display: inline-block;
  animation-delay: 3s;
}
@keyframes wave-animation {
  0% { transform: rotate( 0.0deg) }
 10% { transform: rotate(14.0deg) }  /* The following five values can be played with to make the waving more or less extreme */
 20% { transform: rotate(-8.0deg) }
 30% { transform: rotate(14.0deg) }
 40% { transform: rotate(-4.0deg) }
 50% { transform: rotate(10.0deg) }
 60% { transform: rotate( 0.0deg) }  /* Reset for the last half to pause */
100% { transform: rotate( 0.0deg) }
}

@keyframes slide-up-firstmsg {
  0% {
    opacity: 0;
    transform: translateY(20%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}