.localVideo div:first-child {
    /* height: 83vh !important; */
}

.h-83 {
    position: absolute !important;
    width: 30% !important;
    height: 17% !important;
    bottom: 15% !important;
    right: 4% !important;
}



#controlsToolbar {
    z-index: 111111111111;
    display: flex;
    justify-content: center;
    gap: 10px;
    position: absolute;
    bottom: 11%;
    width: 100%;
}

#mediaControls i {
    font-size: 1em;
    padding: 18px;
    border-radius: 40%;
    color: #ffff;
}

.bg-green {
    background-color: #0297a6;
}

.bg-red {
    background-color: #97238c;
}

.remote-video-container div:first-child {
    height: 90vh !important;
}

.h-90 {
    height: 90vh !important;
}


.video-container {
    position: relative;
    height: 90vh;
}