.banner-section {
    background-color: #f1e5fd;
    padding: 15px 15px 0px;
    border-radius: 5px;
    /* width: 93% !important; */
    margin: 0 auto;
}

.banner-image {
    position: relative;
    height: 190px;
    left: 90px;
    width: 280px;
}

.ribbon {
    position: absolute;
    right: -5px;
    top: -5px;
    z-index: 1;
    overflow: hidden;
    width: 80px;
    height: 80px;
    text-align: right;
  }
  .ribbon span {
    font-size: 0.8rem;
    color: #fff;
    text-transform: uppercase;
    text-align: center;
    font-weight: bold;
    line-height: 32px;
    transform: rotate(45deg);
    width: 125px;
    display: block;
    background: #B138C5;
    background: linear-gradient(99.8deg, #B138C5 0%, #7615D3 100%);
    box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
    position: absolute;
    top: 17px; 
    right: -29px; 
  }
  
  .ribbon span::before {
     content: '';
     position: absolute; 
     left: 0px; top: 100%;
     z-index: -1;
     border-left: 3px solid #B138C5;
     border-right: 3px solid transparent;
     border-bottom: 3px solid transparent;
     border-top: 3px solid #B138C5;
  }
  .ribbon span::after {
     content: '';
     position: absolute; 
     right: 0%; top: 100%;
     z-index: -1;
     border-right: 3px solid #B138C5;
     border-left: 3px solid transparent;
     border-bottom: 3px solid transparent;
     border-top: 3px solid #B138C5;
  }